import { AmplitudeContext } from "@hygo/shared/amplitude";
import { UserContext } from "@hygo/shared/contexts";
import { useRPG } from "@hygo/shared/feature-fields-manager";
import { Field, FieldsManagerEvents, RPGField } from "@hygo/shared/models";
import { COLORS } from "@hygo/shared/utils";
import { CropsScreenContext } from "@hygo/web/contexts";
import { CreationMode, DashboardMode } from "@hygo/web/models";
import { CropIcon, Loader } from "@hygo/web/ui-components";
import { Error } from "@hygo/web/ui-components";
import { fitMapBounds } from "@hygo/web/utils";
import { GoogleMap, Libraries, LoadScriptNext, OverlayView, OverlayViewF, Polygon } from "@react-google-maps/api";
import _ from "lodash";
import { Fragment, useContext, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

const mapLibs: Libraries = ["drawing", "places"];

const options: google.maps.MapOptions = {
	clickableIcons: false,
	fullscreenControl: false,
	mapTypeControl: false,
	mapTypeId: "hybrid",
	streetViewControl: false,
	zoomControl: true,
	zoomControlOptions: {
		position: 3
	}
};

const MarkerWrapper = styled.div`
	background-color: var(--night-100);
	border-radius: 4px;
	padding: 8px;
	width: fit-content;
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 4px;
	white-space: nowrap;
	color: var(--white-100);
`;

const MarkerTitle = styled.h5``;

const containerStyle = {
	height: "100%"
};

const MapView = (): JSX.Element => {
	const { i18n } = useTranslation();
	const { formatFields, logAnalyticEvent } = useContext(AmplitudeContext);
	const { activeFields, crops, farmerSelected, user } = useContext(UserContext);
	const {
		creationMode,
		currentMode,
		editedFieldId,
		fieldCoordinates,
		handleFieldSelection,
		map,
		RPGFields,
		selectedFields,
		setFieldCoordinates,
		setMap,
		setRPGFields
	} = useContext(CropsScreenContext);

	const [zoom, setZoom] = useState<number>(null);
	const [loaded, setLoaded] = useState<boolean>(false);
	const { t } = useTranslation();
	const [error, setError] = useState<boolean>(false);
	const [hideArea, setHideArea] = useState<boolean>(false);
	const showRpg =
		!(fieldCoordinates?.length > 0) &&
		(currentMode === DashboardMode.NEW_FIELD || currentMode === DashboardMode.UPDATE_FIELD) &&
		creationMode === CreationMode.RPG;
	const drawingEnabled =
		(currentMode === DashboardMode.NEW_FIELD || currentMode === DashboardMode.UPDATE_FIELD) &&
		creationMode === CreationMode.DRAWING;

	const { area, center, loadRPGFields, selectRPGField } = useRPG({ fieldCoordinates, RPGFields });
	const showFieldNames =
		zoom >= 15 &&
		(currentMode === DashboardMode.FIELD_LIST ||
			currentMode === DashboardMode.CROPS_LIST ||
			(currentMode === DashboardMode.NEW_FIELD && creationMode === CreationMode.DRAWING));

	const onLoad = (loadedMap: google.maps.Map): void => {
		fitMapBounds({ fields: activeFields, map: loadedMap, userLocation: user?.location });
		setMap(loadedMap);
		setZoom(loadedMap.getZoom());
	};
	const ref = useRef<google.maps.Polyline>();

	const handleClick = (clickedField: Field): void => {
		if (currentMode !== DashboardMode.FIELD_LIST) return;
		logAnalyticEvent(FieldsManagerEvents.selectFieldFromMap, {
			field: formatFields([clickedField], crops),
			type: selectedFields?.find((field) => field.id === clickedField.id) ? "Retrait" : "Ajout"
		});
		handleFieldSelection({
			centerMap: true,
			field: clickedField,
			overrideActiveFields: null,
			overrideNewSelectedFields: null,
			selection: false
		});
	};

	const onEditCoordinates = (): void => {
		setHideArea(false);
		setFieldCoordinates(
			ref?.current
				?.getPath()
				?.getArray()
				?.map((f) => ({ lat: f.lat(), lon: f.lng() }))
		);
	};

	const fetchRPGFields = async (mapInstance: google.maps.Map): Promise<void> => {
		if (!mapInstance) return;
		const fetchedRPG = await loadRPGFields({
			latitude: mapInstance?.getCenter()?.lat(),
			longitude: mapInstance?.getCenter()?.lng()
		});
		setRPGFields(fetchedRPG);
	};

	const onDrawNewField = (coords: { lat: number; lon: number }): void => {
		setFieldCoordinates((prev) => [...prev, coords]);
	};

	const onClickRPGField = (field: RPGField, isAlreadyUsed: boolean): void => {
		selectRPGField(isAlreadyUsed, () => {
			setFieldCoordinates(field?.coordinates?.slice(0, -1));
			fitMapBounds({ fields: [field], map, userLocation: user?.location });
		});
	};

	if (error) {
		return <Error type={500} />;
	}

	return (
		<LoadScriptNext
			googleMapsApiKey={`${process.env.NX_PUBLIC_GOOGLE_MAPS_API}&loading=async`}
			key={`map-${i18n.resolvedLanguage}`}
			language={i18n.resolvedLanguage}
			libraries={mapLibs}
			onError={() => setError(true)}
			onLoad={() => setLoaded(true)}
		>
			{loaded ? (
				<GoogleMap
					mapContainerStyle={containerStyle}
					onClick={(e) => {
						drawingEnabled && onDrawNewField({ lat: e.latLng.lat(), lon: e.latLng.lng() });
					}}
					onIdle={() => farmerSelected && fetchRPGFields(map)}
					onLoad={onLoad}
					onZoomChanged={() => map && setZoom(map.getZoom())}
					options={options}
				>
					{!showRpg &&
						activeFields
							?.filter((f) => f.id !== editedFieldId && f?.coordinates)
							?.map((f) => {
								const crop = crops.find((c) => c.id === f?.cropId);

								return (
									<Fragment key={f.id}>
										<Polygon
											onClick={() => handleClick(f)}
											options={{
												fillColor: selectedFields?.find((fd: Field) => fd.id === f.id)
													? COLORS.LAKE[100]
													: COLORS.WHITE[100],
												fillOpacity: 0.5,
												strokeColor: selectedFields?.find((fd: Field) => fd.id === f.id)
													? COLORS.LAKE[100]
													: COLORS.WHITE[100],
												strokeOpacity: 1,
												strokeWeight: 5
											}}
											path={f.coordinates?.map((c) => ({ lat: c.lat, lng: c.lon }))}
										/>

										{showFieldNames && (
											<OverlayViewF
												getPixelPositionOffset={(width, height) => {
													return { x: -width / 2, y: -height / 2 };
												}}
												mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
												position={{
													lat: f.location.lat,
													lng: f.location.lon
												}}
											>
												<MarkerWrapper>
													{crop && (
														<CropIcon
															crop={crop}
															fill={COLORS.WHITE[100]}
															height={16}
															width={16}
														/>
													)}
													<MarkerTitle>{f.name}</MarkerTitle>
												</MarkerWrapper>
											</OverlayViewF>
										)}
									</Fragment>
								);
							})}
					{showRpg &&
						RPGFields?.map((field) => {
							const fieldAlreadyUsed = activeFields.some((c) =>
								_.isEqual(c.coordinates, field.coordinates)
							);

							return (
								<Polygon
									key={field.id.toString()}
									onClick={() => onClickRPGField(field, fieldAlreadyUsed)}
									options={{
										fillColor: fieldAlreadyUsed ? COLORS.TANGERINE[100] : COLORS.WHITE[100],
										fillOpacity: 0.5,
										strokeColor: fieldAlreadyUsed ? COLORS.TANGERINE[100] : COLORS.WHITE[100],
										strokeWeight: 5
									}}
									path={field.coordinates?.map((c) => ({ lat: c.lat, lng: c.lon }))}
								/>
							);
						})}
					{fieldCoordinates?.length > 0 && (
						<>
							<Polygon
								draggable
								editable
								onDragStart={() => setHideArea(true)}
								onLoad={(e) => (ref.current = e)}
								onMouseUp={onEditCoordinates}
								options={{
									fillColor: COLORS.LAKE[100],
									fillOpacity: 0.5,
									strokeColor: COLORS.LAKE[100],
									strokeOpacity: 1,
									strokeWeight: 5
								}}
								path={fieldCoordinates.slice(0, -1).map((c) => ({ lat: c.lat, lng: c.lon }))}
							/>
							{center && area && !hideArea && (
								<OverlayViewF
									mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
									position={{
										lat: center[1],
										lng: center[0]
									}}
								>
									<MarkerWrapper>
										<MarkerTitle>
											{area} {t("units.hectare")}
										</MarkerTitle>
									</MarkerWrapper>
								</OverlayViewF>
							)}
						</>
					)}
				</GoogleMap>
			) : (
				<Loader />
			)}
		</LoadScriptNext>
	);
};
export default MapView;
